import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"
import {
  faExternalLinkSquareAlt,
  faUserClock,
} from "@fortawesome/pro-solid-svg-icons"
// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import RoomIcon from "@material-ui/icons/Room"
// page styles
import headlinerStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/headlinerStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// react libraries
import Fade from "react-reveal/Fade"

const useStyles = makeStyles(headlinerStyle)

export default function SectionHeadliner() {
  const {
    chilimaniaLogo,
    chilimaniaHeadlinerImage,
    countryEdgeLogo,
    countryEdgeHeadlinerImage,
    headlinerBackground,
    placeholderImage,
  } = useStaticQuery(graphql`
    query getHeadlinerImages {
      chilimaniaLogo: file(
        relativePath: { eq: "logos/chilimania-logo-transparent-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      chilimaniaHeadlinerImage: file(
        relativePath: { eq: "headliners/2024/back-in-black.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      countryEdgeLogo: file(
        relativePath: { eq: "logos/country-edge-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      countryEdgeHeadlinerImage: file(
        relativePath: { eq: "headliners/2024/josh-turner.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      headlinerBackground: file(
        relativePath: { eq: "backgrounds/stage-headliner.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      placeholderImage: file(
        relativePath: { eq: "headliners/headliner-placeholder-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={headlinerBackground.childImageSharp.gatsbyImageData}
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <h2 className={classes.headlinerTitle}>Headliners</h2>
          <GridContainer
            className={classes.headlinerContainer}
            alignItems="flex-start"
          >
            <GridItem xs={12} sm={8} md={6} className={classes.headlinerItem}>
              <Fade left>
                <div className={classes.logoContainer}>
                  <GatsbyImage
                    image={countryEdgeLogo.childImageSharp.gatsbyImageData}
                    alt="Country Edge Logo"
                    className={classes.eventLogo}
                    objectFit={"contain"}
                  />
                </div>
              </Fade>
              <Fade delay={1000}>
                <Card profile>
                  <CardHeader image>
                    <a href="oops" onClick={(e) => e.preventDefault()}>
                      <GatsbyImage
                        image={placeholderImage.childImageSharp.gatsbyImageData}
                        className={classes.cardBackgroundImage}
                        alt="Country Edge Headliner"
                      />
                    </a>
                  </CardHeader>
                  <CardBody>
                    <h3 className={classes.cardTitle}>TBA</h3>
                    <p className={classes.cardDescription}>
                      Country Edge Headliner Coming Soon!
                    </p>
                    {/* <p className={classes.cardDescription}>
                      With timeless songs like “Long Black Train” and “Why Don’t
                      We Just Dance,” Josh Turner’s global reach has surpassed
                      5.8B global streams, 6.5M album sales and almost 12M track
                      sales world - wide. Turner’s five No. 1 singles include
                      “Your Man,” of which the official music video surpassed
                      223 million views.
                      <br />
                      <br />
                      Turner’s album Long Black Train celebrated its 20th
                      anniversary in 2023 and was certified Platinum by the RIAA
                      with nearly 1.5M album sales, 1.3M track downloads and
                      almost 315M life-to-date streams. The lead single of the
                      same name was also certified Platinum making up over 1M
                      track downloads and almost 245M global streams.
                      <br />
                      <br />A native of South Carolina, Turner has been
                      nominated for three GMA Dove A wards and won his first GMA
                      Dove Award in 2021 for “I Saw The Light” featuring Sonya
                      Isaacs, off his I Serve a Savior album. Turner has been
                      nominated for two GRAMMY Awa rds, five CMA Awards, two ACM
                      Awards, one CMT Award, seven ACA Awards and has received
                      six Inspirational Country Music Awards.
                    </p> */}
                    <h5 className={classes.headerIconWrapper}>
                      <RoomIcon className={classes.icons} viewBox="0 0 13 25" />
                      Country Edge Main Stage
                    </h5>
                    <h5 className={classes.headerIconWrapper}>
                      <FontAwesomeIcon
                        icon={faUserClock}
                        className={classes.icons}
                      />
                      TBA
                    </h5>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {/* <Button
                      href="https://twitter.com/joshturnermusic"
                      justIcon
                      round
                      color="twitter"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Button>
                    <Button
                      href="https://www.instagram.com/joshturnermusic/"
                      justIcon
                      round
                      color="instagram"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Button>
                    <Button
                      href="https://www.facebook.com/joshturner/"
                      justIcon
                      round
                      color="facebook"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </Button>
                    <Button
                      href="https://www.youtube.com/user/joshturner"
                      justIcon
                      round
                      color="youtube"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </Button>
                    <Button
                      href="https://www.joshturner.com/"
                      justIcon
                      round
                      color="google"
                    >
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                    </Button> */}
                  </CardFooter>
                </Card>
              </Fade>
            </GridItem>
            <GridItem xs={12} sm={8} md={6} className={classes.headlinerItem}>
              <Fade right delay={500}>
                <div className={classes.logoContainer}>
                  <GatsbyImage
                    image={chilimaniaLogo.childImageSharp.gatsbyImageData}
                    alt="Chilimania Logo"
                    className={classes.eventLogo}
                    objectFit={"contain"}
                  />
                </div>
              </Fade>
              <Fade delay={1000}>
                <Card profile>
                  <CardHeader className={classes.cardHeaderImage} image>
                    <GatsbyImage
                      image={placeholderImage.childImageSharp.gatsbyImageData}
                      className={classes.cardBackgroundImage}
                      alt="Chilimania Headliner"
                      // imgStyle={{ objectFit: "contain" }} Just for 2024
                    />
                  </CardHeader>
                  <CardBody>
                    <h3 className={classes.cardTitle}>TBA</h3>
                    {/* <p className={classes.cardDescription}>
                      The Back in Black tribute band is an incredible, exciting,
                      high energy reproduction of a concert performance of
                      AC/DC. All the members of Back in Black live in
                      Dallas/Fort Worth area and are experienced musicians not
                      only dedicated to the sound and look, but also capturing
                      the power and excitement of AC/DC.
                      <br />
                      <br />
                      20th Century Fox chose Back in Black over all other
                      tribute bands to record AC/DC’s classics “TNT” and “A Long
                      Way to the Top” for two of their movies, Napoleon Dynamite
                      and Garage Days. Back in Black has performed with Cheap
                      Trick, Foghat, Bad Company, Loverboy, Blue Oyster Cult,
                      Grand Funk Railroad, and Ted Nugent.
                      <br />
                      <br />
                      Back in Black is: Darren Caperna – Vocals, Mike Mroz –
                      Lead Guitar, Sheldon Conrad – Bass, Ramiro Noriega –
                      Guitar, Ken Schiumo – Drums
                    </p> */}
                    <p className={classes.cardDescription}>
                      Chilimania Headliner Coming Soon!
                    </p>
                    <h5 className={classes.headerIconWrapper}>
                      <RoomIcon className={classes.icons} viewBox="0 0 13 25" />
                      105.9 THE HOG Main Stage
                    </h5>
                    <h5 className={classes.headerIconWrapper}>
                      <FontAwesomeIcon
                        icon={faUserClock}
                        className={classes.icons}
                      />
                      TBA
                    </h5>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {/* <Button
                      href="https://twitter.com/RealBackInBlack"
                      justIcon
                      round
                      color="twitter"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </Button>
                    <Button
                      href="https://www.instagram.com/realbackinblack/"
                      justIcon
                      round
                      color="instagram"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </Button>
                    <Button
                      href="https://www.facebook.com/BackinBlack.info"
                      justIcon
                      round
                      color="facebook"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </Button>
                    <Button
                      href="https://www.youtube.com/channel/UCpHflr7WMmz_ygvH8IHJ2Dg"
                      justIcon
                      round
                      color="youtube"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </Button>
                    <Button
                      href="http://www.backinblack.info/"
                      justIcon
                      round
                      color="google"
                    >
                      <FontAwesomeIcon icon={faExternalLinkSquareAlt} />
                    </Button> */}
                  </CardFooter>
                </Card>
              </Fade>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
